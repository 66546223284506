import request from '../../client';

const getOrder = (orderId) =>
  request({
    uri: `orders/${orderId}`,
    options: {
      method: 'GET',
    },
  });

const placeOrder = (orderData) => {
  // eslint-disable-next-line no-console
  console.error(`PlaceOrder called with: ${JSON.stringify(orderData)}`);
  return request({
    uri: 'orders/',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(orderData),
    },
  });
};

const authorizeSession = ({ provider }) =>
  request({
    uri: 'orders/authorize-session',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ provider }),
    },
  });

const updateCheckoutSession = ({ provider, checkoutSessionId }) =>
  request({
    uri: 'orders/checkout-session',
    options: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ provider, checkoutSessionId }),
    },
  });

const ordersService = {
  getOrder,
  placeOrder,
  authorizeSession,
  updateCheckoutSession,
};

export default ordersService;
