import getToken from "../cognito";
import ordersMasterService from "../orders/ordersMasterService";

const authorizeSession = async (cartId, isDraft, cardToken, callback) => {
  try {
    const response = await ordersMasterService.callToMethod(
      {
        cartId,
        isDraft,
        cardToken,
        methodName: ordersMasterService.methodsMap.authorizeSession,
        paramsObject: { provider: 'CLOVER' },
      },
    );

    callback(response);
  } catch (error) {
    throw new Error(error.message);
  }
};

const createCharge = async ({centAmount, currency, token}) => {
  try {
    let accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      accessToken = await getToken();
      localStorage.setItem('access_token', accessToken);
    }
    const response = await fetch(`${process.env.BASE_API_URL}/payment-hub/create-charge`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          centAmount,
          currency,
          source: token,
          storeKey: process.env.CLOVER_STORE_KEY
        })
      },
    );
    if (response.ok) {
      return await response.json();
    }
    throw response;
  } catch (error) {
    throw new Error(error.message);
  }
};

const paymentJSService = {
  createCharge,
  authorizeSession
};

export default paymentJSService;
